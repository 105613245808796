
import { Component, Vue, Watch } from "vue-property-decorator";
import * as WorkshopFormsService from "@/services/WorkshopFormsService";

@Component
export default class WorkshopFormsStats extends Vue {
    stats = [] as any[];
    selectedStats = [] as Array<{ value: string, ante: { count: number, percentage: number }, post: { count: number, percentage: number } }>;

    questions: string[] = [];

    showNormalized = false;

    selectedQuestion = null;
    anteChartOptions = {
        chart: {
            type: 'donut',
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    postChartOptions = {
        chart: {
            type: 'donut',
        },
        labels: ['Team A', 'Team B', 'Team C', 'Team D', 'Team E'],
        responsive: [{
            breakpoint: 480,
            options: {
                chart: {
                    width: 200
                },
                legend: {
                    position: 'bottom'
                }
            }
        }]
    }

    anteBarChartOptions = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                columnWidth: '55%',
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: [] as any[],
        },
        yaxis: {
            title: {
                text: '#'
            }
        },
        fill: {
            opacity: 1
        },
    }

    anteBarSeries = [{
        name: 'Ante',
        data: [] as number[]
    }]

    postBarSeries = [{
        name: 'Post',
        data: [] as number[]
    }]

    postBarChartOptions = {
        chart: {
            type: 'bar',
            height: 350,
        },
        plotOptions: {
            bar: {
                columnWidth: '55%',
                endingShape: 'rounded',
                dataLabels: {
                    position: 'top', // top, center, bottom
                },
            },
        },
        dataLabels: {
            enabled: true,
            formatter: function (val: any) {
                return val + "%";
            },
            offsetY: -20,
            style: {
                fontSize: '12px',
                colors: ["#304758"]
            }
        },
        xaxis: {
            categories: [] as any[],
        },
        yaxis: {
            title: {
                text: '#'
            }
        },
        fill: {
            opacity: 1
        },
    }

    async mounted() {
        const result = await WorkshopFormsService.AggergateForms() as Array<{ question_title: string, response_value: string, response_count: string }>;

        this.questions = result.map(e => e.question_title).filter((value, index, self) => self.indexOf(value) === index);
        // group this array by into an array, with question, and then an array responses with value and count and percentage, (make sure value is aggregated by question_title and response_value, and count is aggregated by question_title and response_count)

        this.stats = result;

    }

    @Watch('selectedQuestion')
    onQuestionChange() {
        let responses = [] as Array<{ value: string, ante: { count: number, percentage: number }, post: { count: number, percentage: number } }>;
        const answers = this.stats.filter(e => e.question_title === this.selectedQuestion);

        answers.forEach(a => {
            if (a.response_value === null) return;
            const postOrAnte = a.form_name.toLowerCase().includes('(post)') ? 'Post' : 'Ante';
            console.log(postOrAnte);
            const cleanedAnswer = a.response_value.trim();
            const index = responses.findIndex(e => e.value === cleanedAnswer);
            if (index === -1) {
                responses.push({
                    value: cleanedAnswer,
                    ante: { count: postOrAnte === 'Ante' ? parseInt(a.response_count) : 0, percentage: 0 },
                    post: { count: postOrAnte === 'Post' ? parseInt(a.response_count) : 0, percentage: 0 }
                });
            } else {
                responses[index].ante.count += postOrAnte === 'Ante' ? parseInt(a.response_count) : 0;
                responses[index].post.count += postOrAnte === 'Post' ? parseInt(a.response_count) : 0;
            }
        })

        // sort alphabetically based on value
        responses.sort((a, b) => a.value.localeCompare(b.value));

        // Calculate percentages for post and ante compared to its OWN total
        responses.forEach(r => {
            r.ante.percentage = responses.map(e => e.ante.count).reduce((a, b) => a + b, 0) === 0 ? 0 : r.ante.count / responses.map(e => e.ante.count).reduce((a, b) => a + b, 0) * 100;
            r.post.percentage = responses.map(e => e.post.count).reduce((a, b) => a + b, 0) === 0 ? 0 : r.post.count / responses.map(e => e.post.count).reduce((a, b) => a + b, 0) * 100;
        });

        this.anteChartOptions = {
            ...this.anteChartOptions,
            labels: responses.map(e => e.value)
        }


        console.log('anteBarSeries')
        this.anteBarSeries = [{
            name: 'Ante',
            data: responses.map(e => Math.round(e.ante.percentage))
        }]

        this.postBarSeries = [{
            name: 'Post',
            data: responses.map(e => Math.round(e.post.percentage))
        }]


        this.anteBarChartOptions = {
            ...this.anteBarChartOptions,
            xaxis: {
                categories: responses.map(e => e.value)
            }
        }

        this.postBarChartOptions = {
            ...this.postBarChartOptions,
            xaxis: {
                categories: responses.map(e => e.value)
            }
        }

        this.postChartOptions = {
            ...this.postChartOptions,
            labels: responses.map(e => e.value)
        }

        this.selectedStats = responses;

    }

}


