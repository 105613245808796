import { User } from "@/store/user/types";
import api from '@/plugins/axios';

export const ListForms = async (): Promise<any> => {
  const { data } = await api.get('/workshop-forms')


  return data;
}

export const GetForm = async (formId: string): Promise<any> => {
  const { data } = await api.get(`/workshop-forms/${formId}`);

  return data;
}

export const RefreshForm = async (formId: string): Promise<any> => {
  const { data } = await api.put(`/workshop-forms/${formId}`);

  return data;
}

export const AddForm = async (form: string): Promise<any> => {
  const { data } = await api.post('/workshop-forms/' + form);

  return data;
}

export const AggergateForms = async (): Promise<any> => {
  const { data } = await api.post('/workshop-forms/aggregate');

  return data;
}